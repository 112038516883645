<template lang="html">
    <div class="WatNeuro">
        <img v-if="!fileIsVideo" :src="fileUrl">
        <video v-if="fileIsVideo && showVideo" class="video" ref="videoPlayer" @contextmenu="disableRightClick">
            <source :src="fileUrl" type="video/mp4">
            <source :src="fileUrl" type="video/ogg">
        </video>
        <div id="kopernicaRequired">
            <video id="video" playsinline autoplay></video>
            <canvas id="canvas"></canvas>
            <canvas id="mask" style="display: none"></canvas>
            <canvas id="videocanvas" style="display: none"></canvas>
            <canvas id="feedback" style="display: none"></canvas>
            <div id="dot"></div>
            <canvas id="plotting_canvas" style="cursor:crosshair; max-width: 5vw; max-height: 5vh;"></canvas>
        </div>
        <div id="calibrator" style="display: none">
            <div class="caliDot" id="calibDot1" style="top: 0; left:0;" v-on:click='clickPoint("calibDot1")'></div>
            <div class="caliDot" id="calibDot2" style="top: 0; right:0;" v-on:click='clickPoint("calibDot2")'></div>
            <div class="caliDot" id="calibDot3" style="bottom: 0; left:0;" v-on:click='clickPoint("calibDot3")'></div>
            <div class="caliDot" id="calibDot4" style="bottom: 0; right:0;" v-on:click='clickPoint("calibDot4")'></div>
            <div class="caliDot" id="calibDot5" style="top: 50%; left:50%; top: calc(50vh - 17px); left:calc(50vw - 17px);" v-on:click='clickPoint("calibDot5")'></div>
        </div>
        <div id="baseline" style="display: none">
            <div id="baselineDot">
            </div>
        </div>
        <ModalBasic :show="showInitialAdvert" @close="closeAdvert()">
            <template #content>
                <div class="WatNeuro--ModalContent">
                    <p  v-html="$t('wat_neuro_advert_msg')"></p>
                    <p>{{ $t('wat_neuro_advert_msg_final') }}</p>
                    <Button style="background:#96C800; color: white;" v-on:click="showInitialAdvert = false; closeAdvert()">{{ $t('continue_mode') }}</Button>
                </div>
            </template>
        </ModalBasic>
    </div>
</template>
<script>
import ModalBasic from '../ModalBasic/ModalBasic.vue';
import Button from '../Button/Button.vue';
    export default {
    name: 'WatNeuro',
    components:{
        ModalBasic,
        Button
    },
    props:{
        fileUrl:{
            type: String,
            default: ''
        },
        taskNum:{
            type: [String, Number],
            default: 0
        },
        testId:{
            type: [String, Number],
            default: 0
        },
        userCode:{
            type: [String, Number],
            default:0
        },
        fileIsVideo: {
            type: Boolean,
            default: false,
        },
        suitePlanId: {
            type: [String, Number],
            default: 0
        }
    },
    data(){
        return{
            calibCount : 0,
            remainingN1 : 3,
            remainingN2 : 3,
            remainingN3 : 3,
            remainingN4 : 3,
            remainingN5 : 3,
            showInitialAdvert: true,
            showVideo: false
        }
    },
    methods:{
        startGazer() {
            const vm = this
            const Owner = 'WAT'
            startKopernica(this.userCode, this.testId, this.suitePlanId, true);

            kpn_eyetracker.setRegression('ridge')
                .setGazeListener(function(data, clock) {})
                .begin()
                .showPredictionPoints(true);

            var setup = function() {
                var canvas = document.getElementById("plotting_canvas");
                canvas.width = window.innerWidth;
                canvas.height = window.innerHeight;
                canvas.style.position = 'fixed';
            };

            function checkIfReady() {
                if (kpn_eyetracker.isReady()) {
                    setup();
                    vm.startBaselineCalibrator()
                } else {
                    setTimeout(function() {
                        checkIfReady();
                    }, 100);
                }
            }
            setTimeout(function() {
                checkIfReady();
            }, 100);
        },
        startBaselineCalibrator(){
            const vm = this
            document.getElementById("baseline").style.display = "initial";
            startBaseLine();
            setTimeout(function() {
                pauseRecording();
                document.getElementById("baseline").style.display = "none";
                vm.startEyetrackerCalibrator()
            }, 10000);
            this.showVideo = true
        },
        startEyetrackerCalibrator(){
            /*Restart the counters*/
            this.calibCount = 0;
            this.remainingN1 = 3;
            this.remainingN2 = 3;
            this.remainingN3 = 3;
            this.remainingN4 = 3;
            this.remainingN5 = 3;
            /*Make visible the calibration dots*/
            document.getElementById("calibDot1").style.display = "initial";
            document.getElementById("calibDot2").style.display = "initial";
            document.getElementById("calibDot3").style.display = "initial";
            document.getElementById("calibDot4").style.display = "initial";
            document.getElementById("calibDot5").style.display = "initial";
            /*Show the calibrator screen*/
            document.getElementById("calibrator").style.display = "initial";
        },
        clickPoint(sender){
            const vm = this
            document.getElementById(sender).style.display = "none";

            setTimeout(function() { vm.respawn(sender); }, 1500);

            if(sender == "calibDot1") this.remainingN1--;
            if(sender == "calibDot2") this.remainingN2--;
            if(sender == "calibDot3") this.remainingN3--;
            if(sender == "calibDot4") this.remainingN4--;
            if(sender == "calibDot5") this.remainingN5--;

            this.calibCount++;
            if(this.calibCount >= 15){ 
                document.getElementById("calibrator").style.display = "none";
                this.startImageBlock()
            }
        },
        respawn(sender){
            if( (sender == "calibDot1" && this.remainingN1 > 0) ||
                (sender == "calibDot2" && this.remainingN2 > 0) ||
                (sender == "calibDot3" && this.remainingN3 > 0) ||
                (sender == "calibDot4" && this.remainingN4 > 0) ||
                (sender == "calibDot5" && this.remainingN5 > 0) )
            document.getElementById(sender).style.display = "initial";
        },
        startImageBlock(){
            let duration = 10000
            if (this.fileIsVideo) {
                this.$refs.videoPlayer.play();
                duration = this.$refs.videoPlayer.duration * 1000;
            }
            const vm = this
            startImageBlock(this.taskNum)
            setTimeout(function() {
                endRecording();
                vm.$emit('go-questions')
            }, duration);
        },
        loadExternalScript(path, lastScript) {
            const vm = this
            const script = document.createElement('script');
            script.src = path;
            document.body.appendChild(script);
            script.onload = () => {
                if(lastScript){
                    setTimeout(function() {
                        window.applyKalmanFilter = true;
                        window.saveDataAcrossSessions = true;
                        window.onbeforeunload = function() {
                            kpn_eyetracker.end();
                        }
                        vm.startGazer();
                    }, 1000);
                }
            };
        },
        disableRightClick(event) {
            event.preventDefault();
        },
        async closeAdvert(){
            this.showInitialAdvert = false  
            await this.loadExternalScript('https://neuro.wearetesters.com/external/camrectest.js', false)
            await this.loadExternalScript('https://neuro.wearetesters.com/external/kpn_eyetracker.js', false)
            await this.loadExternalScript('https://neuro.wearetesters.com/external/main.js', true)
        },
    },
    async mounted(){
        await this.loadExternalScript('https://code.jquery.com/jquery-3.5.1.min.js', false)
    }
}

</script>
    
    
<style lang="scss" scoped>
    #kopernicaRequired{
        position: absolute;
    }
    .video {
        max-width: 100%;
        height: 100%;
        max-height: calc(100vh);
        border: 1px solid lightgray;
        cursor: pointer;
    }
    img{
        max-width: 100%;
        height: auto;
        max-height: calc(100vh);
        border: 1px solid lightgray;
        cursor: pointer;
    }
    .WatNeuro{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: white;
        &--ModalContent{
            max-width: 550px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 20px;
        }
    }

    div#calibrator {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        background-color: wheat;
        z-index: 10;
    }

    div.caliDot {
        min-width: 35px;
        min-height: 35px;
        max-height: 35px;
        max-width: 35px;
        background-color: black;
        display: block;
        position: fixed;
        border-radius: 25px;
    }
    #canvas {
        display: none;
    }
    div#baseline {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        background-color: wheat;
        z-index: 11;
    }

    #baselineDot{
        min-width: 200px;
        min-height: 200px;
        max-height: 200px;
        max-width: 200px;
        background-color: black;
        display: block;
        position: fixed;
        border-radius: 200px;
        top: 40%;
        left:40%;
        /*In cascade, we try to use a calc method to center it better in the supported browsers*/
        top: calc(50vh - 100px);
        left:calc(50vw - 100px);
    }
</style>
          